import React from 'react'

import type { HeadingProps } from './types'

const Heading: React.FunctionComponent<HeadingProps> = ({
  nodeType = 'h1',
  children,
  ...props
}) => {
  if (children) {
    return React.createElement(nodeType, props, children)
  }

  return null
}

export default Heading
